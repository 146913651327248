.Col a {
    text-decoration: none;
}

h3 {
    font-size: 1.4rem !important;
}

h4 {
    font-size: 1.2rem !important;
}

.Card {
    color: whitesmoke !important;
    min-height: 7.2rem;
    background-color: #65495e !important;
    border: 1px solid #65495ea7 !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.IngredientName {
    text-transform: uppercase;
    font-weight: 200;
    font-size: 1rem;
    text-align: left;
}

.Title {
    font-size: 1.5rem !important;
    font-family: "Parisienne", cursive
}

.Subtitle {
    font-weight: 200 !important;
    font-size: .9rem !important;
}