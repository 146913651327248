.Contact,
.FormContainer {
    background-color: transparent !important;
}

.Label {
    font-size: smaller;
}

.SubmitButton {
    background-color: #65495e;
    color: whitesmoke !important;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    border-radius: 0.25rem;
    text-decoration: none;
    text-shadow: none;
    transition: background-color 0.3s ease;
}

.SubmitButton:hover {
    background-color: #4b5563 !important;
}

.CloseButton {
    background-color: #65495e;
    border: none;
    color: whitesmoke !important;
    font-size: .8rem;
    font-weight: 500;
    padding: 0.75rem .75rem;
    border-radius: 0.3rem;
    text-decoration: none;
    text-shadow: none;
    transition: background-color 0.3s ease;
}