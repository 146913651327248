.NotFound {
    font-size: 1.5rem;
    background-color: transparent !important;
}

.Link {
    color: inherit;
    font-weight: 100;
}

.Line,
.Arrow {
    opacity: 0;
    transition: opacity 0.3s ease-in, visibility 0.3s ease-out;
}

.Link:hover .Line,
.Link:hover .Arrow {
    opacity: 1;
}