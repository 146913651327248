.HeroBanner {
    position: relative;
    padding: 100px 0;
    text-align: center;
    color: #f2e8da;
}

.HeroImage {
    background-image: url('../assets/hero.jpg');
    background-size: cover;
    background-position: center;
    filter:
        blur(1px) brightness(0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.HeroContent {
    position: relative;
    z-index: 1;
}

.HeroTitle {
    padding: 0 0 1rem 0;
    font-family: "Parisienne", cursive;
    font-size: 3.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.HeroSubtitle {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.ProductCard {
    background-color: transparent !important;
}

.HeroButton {
    background-color: #65495e;
    border: 1px solid rgba(245, 245, 245, 0.262);
    color: whitesmoke !important;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    border-radius: 0.3rem;
    text-decoration: none;
    text-shadow: none;
    transition: background-color 0.3s ease;
}

.HeroButton:hover {
    background-color: #4b5563;
}

@media (max-width: 576px) {
    .HeroTitle {
        font-size: 2.3rem;
    }

    .HeroSubtitle {
        font-size: 1rem;
    }

    .HeroButton {
        font-size: 0.875rem;
        padding: 0.5rem 1rem;
    }
}