a {
    color: #363951 !important;
}

.Footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f2e9e4;
    padding: 5px 0;
    transition: all 0.3s ease-in-out;
    z-index: 1000;
}

.Footer.expanded {
    padding-bottom: 20px;
}

.ToggleButton {
    color: inherit !important;
    font-size: .875rem !important;
    font-weight: 400 !important;
    border: none !important;
    padding: 0 1rem 0 1rem !important;
    cursor: pointer;
}

.Disclaimer {
    font-size: 0.9rem;
    color: #4a4e69;
    margin: 10px 0;
}

.Copyright {
    font-size: 0.875rem;
    color: #4a4e69;
    margin-top: 5px;
    text-align: center;
}