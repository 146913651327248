.Navbar {
    position: absolute;
    top: 0;
    background-color: #f2e9e4 !important;
    transition: all 0.3s ease-in-out;
    z-index: 1000;
}

.NavbarRow {
    display: contents !important;
}

.Hamburger:focus {
    box-shadow: none !important;
}

.Hamburger[aria-expanded="true"] {
    box-shadow: none !important;
}

.Nav {
    text-align: right !important;
    padding-right: .8rem;
}

.NavbarBrandContainer {
    position: relative;
    text-align: center;
}

.Brand {
    position: relative;
    z-index: 1;
    color: #65495e !important;
    font-family: "Parisienne", cursive;
    font-size: 1.7rem !important;
    font-weight: 800;
    margin-top: .3rem;
    font-style: normal;
    display: inline-block;
}

.Logo {
    position: absolute;
    top: 62%;
    left: -28px;
    transform: translateY(-50%) rotate(296deg);
    width: 125px;
    height: 125px;
}