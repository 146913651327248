h2 {
    color: #4b5563;
}

h4 {
    font-size: 1.2rem !important;
    text-transform: uppercase;
    font-weight: 200 !important;
    font-size: 1rem;
    text-align: left;
}

.FormLabel {
    font-size: 1rem;
}

.ProductList {
    padding-left: 1rem;
    font-size: .9rem;
}

.TestimonialCard {
    padding: 0 50px 50px;
    background-color: transparent !important;
}

.TestimonialBody {
    height: 325px;
    overflow: hidden;
}

.Product {
    text-transform: lowercase;
    font-size: smaller;
}

.ReviewText {
    text-align: left;
    overflow-y: auto;
    font-size: 16px;
}

.SubmitButton {
    background-color: #65495e;
    color: whitesmoke !important;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    border-radius: 0.3rem;
    text-decoration: none;
    text-shadow: none;
    transition: background-color 0.3s ease;
}

.SubmitButton:hover {
    background-color: #4b5563 !important;
}

.CloseButton {
    background-color: #65495e;
    border: none;
    color: whitesmoke !important;
    font-size: .8rem;
    font-weight: 500;
    padding: 0.75rem .75rem;
    border-radius: 0.3rem;
    text-decoration: none;
    text-shadow: none;
    transition: background-color 0.3s ease;
}

@media (max-width: 576px) {
    .TestimonialBody {
        height: 375px;
    }
}