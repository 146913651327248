br {
    line-height: 2rem;
}

.Card {
    background-color: #f2e9e4 !important;
}

.Title {
    font-size: 2rem;
    font-family: "Parisienne", cursive
}

.Text {
    text-align: justify;
}

.Warning {
    font-weight: 600;
    font-size: .8rem;
    text-transform: uppercase;
}

.Ingredients {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.5rem;
}

.IngredientName {
    text-transform: uppercase;
    font-weight: 200;
    font-size: 1rem;
}

.Back {
    padding: 1rem 0 5rem 0;
    font-size: .9rem;
}

.Button {
    background-color: transparent;
    text-decoration: underline !important;
    box-sizing: border-box;
    color: #111827;
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.25rem;
    padding: .75rem 1rem;
    text-align: center;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.Button:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.Button:focus-visible {
    box-shadow: none;
}